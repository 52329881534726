<template>
  <div class="container-fluid main-color">
    <!-- start page title -->
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <h4 class="page-title">
            {{
              currentRouteName == "grading-evaluation-management-update"
                ? "Edit Record"
                : "Add Record"
            }}
          </h4>
        </div>
      </div>
    </div>
    <!-- end page title -->
    <div class="row">
      <div class="col-lg-12">
        <place-holder v-if="loading"></place-holder>
        <div class="card">
          <div v-if="!loading" class="card-body">
            <form class="parsley-examples">
              <div class="row">
                <div class="col-md-7">
                  <div class="row mb-3">
                    <label for="name" class="col-md-3 form-label">
                      Program Name
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-8" v-if="hasPermissions(['grading-evaluation-create', 'grading-evaluation-update'])">
                      <v-select
                        @option:selected="getSelectedProgram"
                        v-model="selectedProgram"
                        label="name"
                        :disabled="
                          currentRouteName ==
                          'grading-evaluation-management-update'
                        "
                        :options="programs"
                        :selectable="(options) => isSelectableProgram(options)"
                        :clearable="false"
                        :class="{
                          'p-invalid':
                            v$.selectedProgram.$error || errorFor('name'),
                        }"
                      >
                      </v-select>
                      <v-errors
                        :serverErrors="errorFor('name')"
                        :vuelidateErrors="{
                          errors: v$.selectedProgram.$errors,
                          value: 'Program Name',
                        }"
                      ></v-errors>
                    </div>

                    <div class="col-md-8" v-else-if="hasPermissions(['grading-evaluation-list'])">
                      <v-select
                        v-model="selectedProgram"
                        label="name"
                        disabled
                        :clearable="false"
                      >
                      </v-select>
                    </div>

                  </div>

                  <div class="row mb-3">
                    <label for="short_name" class="col-md-3 form-label">
                      Batch
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-8" v-if="hasPermissions(['grading-evaluation-create', 'grading-evaluation-update'])">
                      <v-select
                        @option:selected="getSelectedBatch"
                        v-model="selectedBatch"
                        label="batch_no"
                        :disabled="
                          currentRouteName ==
                          'grading-evaluation-management-update'
                        "
                        :options="batch"
                        :selectable="(options) => isSelectableBatch(options)"
                        :clearable="false"
                        :class="{
                          'p-invalid':
                            v$.selectedBatch.$error || errorFor('name'),
                        }"
                      >
                      </v-select>
                      <v-errors
                        :serverErrors="errorFor('name')"
                        :vuelidateErrors="{
                          errors: v$.selectedBatch.$errors,
                          value: 'Batch',
                        }"
                      ></v-errors>
                    </div>

                    <div class="col-md-8" v-else-if="hasPermissions(['grading-evaluation-list'])">
                      <v-select
                        v-model="selectedBatch"
                        label="batch_no"
                        disabled
                        :clearable="false"
                      >
                      </v-select>
                    </div>
                  </div>

                  <div class="row mb-3">
                    <label for="short_name" class="col-md-3 form-label">
                      Level
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-8" v-if="hasPermissions(['grading-evaluation-create', 'grading-evaluation-update'])">
                      <v-select
                        @option:selected="getSelectedLevel"
                        v-model="selectedLevel"
                        label="name"
                        :disabled="
                          currentRouteName ==
                          'grading-evaluation-management-update'
                        "
                        :options="levels"
                        :selectable="(options) => isSelectableLevel(options)"
                        :clearable="false"
                        :class="{
                          'p-invalid':
                            v$.selectedLevel.$error || errorFor('level'),
                        }"
                      >
                      </v-select>
                      <v-errors
                        :serverErrors="errorFor('level')"
                        :vuelidateErrors="{
                          errors: v$.selectedLevel.$errors,
                          value: 'Level',
                        }"
                      ></v-errors>
                    </div>

                    <div class="col-md-8" v-else-if="hasPermissions(['grading-evaluation-list'])">
                      <v-select
                        v-model="selectedLevel"
                        label="name"
                        disabled
                        :clearable="false"
                      >
                      </v-select>
                    </div>
                  </div>

                  <div class="row mb-3">
                    <label for="short_name" class="col-md-3 form-label">
                      Employee name
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-8" v-if="hasPermissions(['grading-evaluation-create', 'grading-evaluation-update'])">
                      <v-select
                        @option:selected="getSelectedEmployee"
                        v-model="selectedEmployee"
                        label="employee_name"
                        :disabled="
                          currentRouteName ==
                          'grading-evaluation-management-update'
                        "
                        :options="employeeLists"
                        :selectable="(options) => isSelectable(options)"
                        :clearable="false"
                        :class="{
                          'p-invalid':
                            v$.selectedEmployee.$error || errorFor('name'),
                        }"
                      >
                      </v-select>
                      <v-errors
                        :serverErrors="errorFor('name')"
                        :vuelidateErrors="{
                          errors: v$.selectedEmployee.$errors,
                          value: 'Employee Name',
                        }"
                      ></v-errors>
                    </div>

                    <div class="col-md-8" v-else-if="hasPermissions(['grading-evaluation-list'])">
                      <v-select
                        v-model="selectedEmployee"
                        label="employee_name"
                        disabled
                        :clearable="false"
                      >
                      </v-select>
                    </div>

                  </div>
                </div>

                <div class="row col-md-5">
                  <div class="row mb-3">
                    <label for="short_name" class="col-md-4 form-label">
                      Employee ID
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-8">
                      <input
                        type="text"
                        class="form-control"
                        v-model="gradingEvaluationDetail.employee_id"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="row mb-3">
                    <label for="date" class="col-md-4 form-label">
                      Joined Date
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-8">
                      <input
                        type="text"
                        class="form-control"
                        v-model="gradingEvaluationDetail.join_date"
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="progress mt-3"
                style="height: 0.5px; background-color: #ccc !important"
              >
                <div class="progress-bar" role="progressbar"></div>
              </div>

              <div v-if="isFullDetails == null">
                <div class="row mt-3">
                  <label for="date" class="col-md-2 form-label fw-bold">
                    Criteria
                  </label>
                  <label for="date" class="col-md-2 form-label fw-bold">
                    Type
                  </label>
                  <label for="date" class="col-md-3 form-label fw-bold">
                    Target Point
                  </label>
                  <label for="date" class="col-md-3 form-label fw-bold">
                    Actual Point
                  </label>
                </div>

                <div>
                  <div
                    class="row mt-3"
                    v-for="(item, index) in inclassCriterias"
                    :key="index"
                  >
                    <div class="col-md-2">
                      <p>{{ item.name }}</p>
                    </div>
                    <div class="col-md-2">
                      <p>Inclass</p>
                    </div>
                    <div class="col-md-3">
                      <input
                        type="number"
                        class="form-control"
                        v-model="item.point"
                        disabled
                      />
                    </div>
                    <div class="col-md-3" v-if="hasPermissions(['grading-evaluation-create', 'grading-evaluation-update'])">
                      <input
                        type="number"
                        class="form-control"
                        :disabled="isApproved"
                        v-model="item.input_inclass_point"
                        @input="
                          inclassChange($event.target.value, index, item.point)
                        "
                        @keydown.enter.prevent
                      />
                      <p
                        class="text-danger"
                        style="font-size: 10px"
                        v-if="item.isMax"
                      >
                        Actual Point should be greater than Target Point.
                      </p>
                    </div>

                    <div class="col-md-3" v-else-if="hasPermissions(['grading-evaluation-list'])">
                      <input
                        type="number"
                        :disabled="isApproved"
                        class="form-control"
                        v-model="item.input_inclass_point"
                      />
                    </div>

                    <div class="col-md-2" v-show="inclassCriterias.length > 1" v-if="hasPermissions(['grading-evaluation-create', 'grading-evaluation-update'])">
                      <div
                        v-show="!loading"
                        @click="removeInclassCriteria(index, item.input_inclass_point)"
                      >
                        <i class="fas fa-trash-alt mx-1"></i>
                      </div>
                    </div>
                  </div>

                  <div
                    class="row mt-3"
                    v-for="(item, index) in ojtCriterias"
                    :key="index"
                  >
                    <div class="col-md-2">
                      <p>{{ item.name }}</p>
                    </div>
                    <div class="col-md-2">
                      <p>OJT</p>
                    </div>
                    <div class="col-md-3">
                      <input
                        type="number"
                        class="form-control"
                        v-model="item.point"
                        disabled
                      />
                    </div>
                    <div class="col-md-3" v-if="hasPermissions(['grading-evaluation-create', 'grading-evaluation-update'])">
                      <input
                        type="number"
                        class="form-control"
                        :disabled="isApproved"
                        v-model="item.input_ojt_point"
                        @input="
                          ojtChange($event.target.value, index, item.point)
                        "
                        @keydown.enter.prevent
                      />
                      <p
                        class="text-danger"
                        style="font-size: 10px"
                        v-if="item.isMax"
                      >
                        Actual Point should be greater than Target Point.
                      </p>
                    </div>

                    <div class="col-md-3" v-else-if="hasPermissions(['grading-evaluation-list'])">
                      <input
                        type="number"
                        class="form-control"
                        :disabled="isApproved"
                        v-model="item.input_ojt_point"
                      />
                    </div>

                    <div class="col-md-2" v-show="ojtCriterias.length > 1" v-if="hasPermissions(['grading-evaluation-create', 'grading-evaluation-update'])" >
                      <div v-show="!loading" @click="removeOjtCriteria(index, item.input_ojt_point)">
                        <i class="fas fa-trash-alt mx-1"></i>
                      </div>
                    </div>

                  </div>
                </div>

                <div
                  class="progress mt-3"
                  style="height: 0.5px; background-color: #ccc !important"
                >
                  <div class="progress-bar" role="progressbar"></div>
                </div>

                <div class="mt-3">
                  <div class="d-flex" v-if="hasPermissions(['grading-evaluation-create', 'grading-evaluation-update'])">
                    <div class="fw-bold">Officer Credit</div>
                    <div>
                      <input
                        type="checkbox"
                        class="checkBox"
                        v-model="isChecked"
                        :disabled="isApproved"
                        @click="changeOfficerCredit()"
                      />
                    </div>
                  </div>

                  <div class="d-flex" v-else-if="hasPermissions(['grading-evaluation-list'])">
                    <div class="fw-bold">Officer Credit</div>
                    <div>
                      <input
                        type="checkbox"
                        class="checkBox"
                        v-model="isChecked"
                        disabled
                      />
                    </div>
                  </div>

                  <div class="row mt-3" v-show="isChecked">
                    <div class="row">
                      <div class="col-md-4">
                        <div class="fw-bold">Credit Type</div>
                      </div>

                      <div class="col-md-4 ms-5">
                        <div class="fw-bold">Credit Score</div>
                      </div>

                      <div class="col-md-3">
                        <button
                          :disabled="isApproved"
                          class="btn btn-sm ms-3 btn-blue"
                          type="button"
                          @click="addNewOfficer()"
                        >
                          Add Credit
                        </button>
                      </div>
                    </div>
                    <div
                      class="row"
                      v-for="(item, index) in selectedOfficerCreditArray"
                      :key="index"
                    >
                      <div class="mt-2 col-md-4" v-if="hasPermissions(['grading-evaluation-create', 'grading-evaluation-update'])">
                        <v-select
                          :options="officerCredits"
                          @option:selected="getOfficerScore($event, index)"
                          label="name"
                          :clearable="false"
                          :disabled="isApproved"
                          :selectable="(options) => isSelectableOfficer(options)"
                          v-model="item.name"
                          :class="{
                            'p-invalid':
                              v$.selectedOfficerCreditArray.$error ||
                              errorFor('name'),
                          }"
                        >
                        </v-select>
                        <v-errors
                          :serverErrors="errorFor('name')"
                          :vuelidateErrors="{
                            errors: v$.selectedOfficerCreditArray.$errors,
                            value: 'Name',
                          }"
                        ></v-errors>
                      </div>

                      <div class="mt-2 col-md-4" v-else-if="hasPermissions(['grading-evaluation-list'])">
                        <v-select
                          label="name"
                          :clearable="false"
                          v-model="item.name"
                        >
                        </v-select>
                      </div>

                      <div class="mt-2 ms-5 col-md-4">
                        <input
                          type="text"
                          class="form-control"
                          style="width: 60%"
                          v-model="item.score"
                          disabled
                        />
                      </div>

                      <div class="col-md-2 mt-2" v-show="index != 0" v-if="hasPermissions(['grading-evaluation-create', 'grading-evaluation-update'])">
                        <button
                        :disabled="isApproved"
                          v-show="!loading"
                          type="button"
                          @click="removeAnswer(index)"
                          class="btn btn-sm btn-danger waves-effect waves-light mb-2"
                        >
                          Remove
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="progress mt-3"
                  style="height: 0.5px; background-color: #ccc !important"
                >
                  <div class="progress-bar" role="progressbar"></div>
                </div>

                <div class="row mt-3">
                  <div class="col-6">
                    <div class="row">
                      <div class="col-7">
                        <p class="fw-bold">Inclass Score</p>
                      </div>
                      <div class="col-5">
                        <input
                          type="number"
                          class="form-control"
                          v-model="inclassScore.new"
                          data-bs-toggle="tooltip"
                          :title="`inclass-point = ${allScores.inclass_score} , presentation_inclass_point = ${allScores.inclass_presentation_score}`"
                          disabled
                        />
                      </div>
                    </div>

                    <div class="row mt-2">
                      <div class="col-7">
                        <p class="fw-bold">OJT Score</p>
                      </div>
                      <div class="col-5">
                        <input
                          type="number"
                          class="form-control"
                          v-model="ojtScore.new"
                          data-bs-toggle="tooltip"
                          :title="`ojt-point = ${allScores.ojt_score} ,
                            presentation_ojt_point = ${allScores.ojt_presentation_score} ,
                            competency_point = ${allScores.competency_score}, HRD-point = ${allScores.hrd_ojt_score}
                            `"
                          disabled
                        />
                      </div>
                    </div>

                    <div class="row mt-2">
                      <div class="col-7">
                        <p class="fw-bold">Officer Credit</p>
                      </div>
                      <div class="col-5">
                        <input
                          type="number"
                          class="form-control"
                          v-model="officerCreditScore"
                          disabled
                        />
                      </div>
                    </div>
                  </div>

                  <div class="col-6">
                    <div class="row">
                      <div class="col-7">
                        <p class="fw-bold">Total Score</p>
                      </div>
                      <div class="col-5">
                        <input
                          type="number"
                          class="form-control"
                          v-model="totalScore"
                          disabled
                        />
                      </div>
                    </div>

                    <div class="row mt-2">
                      <div class="col-7">
                        <p class="fw-bold">Grade</p>
                      </div>
                      <div class="col-5">
                        <v-select
                          v-model="selectedGrade"
                          label="name"
                          :options="gradingLevel"
                          :disabled="isApproved"
                          :clearable="false"
                        >
                        </v-select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div v-show="isFullDetails" class="mt-3 text-center" v-if="hasPermissions(['grading-evaluation-create', 'grading-evaluation-update'])">
                <p class="text-danger">{{ isFullDetails }}</p>
              </div>
              <div v-if="!isApproved" class="row mt-3">
                <div class="col-md-12">
                  <div class="text-center mt-3 mb-3">
                    <router-link
                      :to="{ name: 'grading-evaluation-management' }"
                    >
                      <button type="button" class="btn w-sm btn-secondary me-5">
                        Cancel
                      </button>
                    </router-link>
                    <!-- <button
                      v-if="assessors.length == 0"
                      style="cursor: not-allowed"
                      data-bs-toggle="tooltip"
                      title="There is no assessors."
                      type="button"
                      class="btn w-sm btn-success waves-effect waves-light px-"
                    >
                      {{ isLoading == true ? "Loading..." : "Save" }}
                    </button> -->

                    
                    <button 
                      v-if="totalScore > 100"
                      :disabled="isLoading ? true : false"
                      type="button"
                      class="btn w-sm btn-success waves-effect waves-light px-3 "
                      data-bs-toggle="tooltip"
                      :class="program_owner_approved == 1 ? 'd-none': ''"
                      title="Total score can't be greater than 100."
                      style="cursor: not-allowed"
                    >
                      {{ isLoading == true ? "Loading..." : "Save" }}
                    </button>
                    <button
                      v-else
                      :disabled="isLoading ? true : false"
                      type="button"
                      class="btn w-sm btn-success waves-effect waves-light px-3"
                      :class="program_owner_approved == 1 ? 'd-none': ''"
                      @click="
                        currentRouteName ===
                        'grading-evaluation-management-update'
                          ? updateGradingEvaluation()
                          : createGradingEvaluation()
                      "
                    >
                      <span
                        v-if="isLoading"
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      {{ isLoading == true ? "Loading..." : "Save" }}
                    </button>
                    
                  </div>
                </div>
                <!-- end col -->
              </div>
              <div v-else class="row mt-3">
                <div class="col-md-12">
                  <div class="text-center mt-3 mb-3">
                    
                    
                    <button 
                      
                      disabled
                      type="button"
                      class="btn w-sm btn-success waves-effect waves-light px-3 "
                      style="cursor: not-allowed"
                    >
                      Approved
                    </button>
                    
                  </div>
                </div>
                <!-- end col -->
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { useToast } from "vue-toastification";
import useVuelidate from "@vuelidate/core";
import { required, requiredIf } from "@vuelidate/validators";
import validationErrors from "../../../mixins/validationErrors";
import userHasPermissions from "../../../mixins/userHasPermissions";
export default {
  setup() {
    const toast = useToast();
    return { v$: useVuelidate(), toast };
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
  },
  mixins: [validationErrors, userHasPermissions],
  data() {
    return {
      isApproved : false,
      programs: [],
      levels: [],
      batch: [],
      existedEmployee: [],
      employeeLists: [],
      gradingEvaluationDetail: {},
      selectedOfficerCreditArray: [
        {
          id: null,
          name: "",
          score: 0,
        },
      ],
      isChecked: false,
      allScores: null,
      isFullDetails: "",
      inclassCriterias: [],
      ojtCriterias: [],
      officerCredits: [],
      gradingLevel: [],
      inclass_criteria_id: 0,
      ojt_criteria_id: 0,
      inclassScore: { original: 0, new: 0 },
      ojtScore: { original: 0, new: 0 },
      officerCreditScore: 0,
      program_owner_approved: 0,
      selectedGrade: null,
      selectedProgram: null,
      selectedBatch: null,
      selectedEmployee: null,
      selectedLevel: null,
      totalScore: 0,
      loading: false,
      isLoading: false,
      baseUrl: process.env.VUE_APP_BASE_URL,
    };
  },
  validations() {
    return {
      selectedProgram: { required },
      selectedBatch: { required },
      selectedEmployee: { required },
      selectedLevel: { required },
      selectedGrade: { required },
      selectedOfficerCreditArray: {
        required: requiredIf(() => {
          return this.isChecked;
        }),
      },
    };
  },
  methods: {
    removeInclassCriteria(index, input_score) {
      this.inclassCriterias.splice(index, 1);
      this.inclassScore.new = this.inclassScore.new - input_score;
      this.updateTotalScore();
    },
    removeOjtCriteria(index, input_score) {
      this.ojtCriterias.splice(index, 1);
      this.ojtScore.new = this.ojtScore.new - input_score;
      this.updateTotalScore();
    },
    inclassChange(value, index, point) {
      this.selectedGrade = null;
      if (parseInt(value) > point) {
        this.inclassCriterias[index].input_inclass_point = point;
        const total =  this.inclassCriterias.reduce((total, criteria) => total + parseInt(criteria.input_inclass_point), 0);
        this.inclassScore.new = total + this.inclassScore.original;
        this.inclassCriterias[index].isMax = true;
      } else if (value == "") {
        this.inclassCriterias[index].input_inclass_point = 0;
      const total =  this.inclassCriterias.reduce((total, criteria) => total + parseInt(criteria.input_inclass_point), 0);
        this.inclassScore.new = total + this.inclassScore.original;
        this.inclassCriterias[index].isMax = false;
        // this.inclassCriterias[index].input_inclass_point = 0;
      } else {
        const total =  this.inclassCriterias.reduce((total, criteria) => total + parseInt(criteria.input_inclass_point), 0);
        this.inclassScore.new = total +this.inclassScore.original;
        this.inclassCriterias[index].isMax = false;
      }
      // this.inclassScore = this
      this.updateTotalScore();
    },
    ojtChange(value, index, point) {
      this.selectedGrade = null;
      if (parseInt(value) > point) {
        this.ojtCriterias[index].input_ojt_point = point;
      const total =  this.ojtCriterias.reduce((total, criteria) => total + parseInt(criteria.input_ojt_point), 0);
        this.ojtScore.new = total+ this.ojtScore.original;

        this.ojtCriterias[index].isMax = true;
      } else if (value == "") {
        this.ojtCriterias[index].input_ojt_point = 0;
      const total =  this.ojtCriterias.reduce((total, criteria) => total + parseInt(criteria.input_ojt_point), 0);
        this.ojtScore.new = total+ this.ojtScore.original;
        this.ojtCriterias[index].isMax = false;
        // this.ojtCriterias[index].input_ojt_point = 0;
      } else {
      const total =  this.ojtCriterias.reduce((total, criteria) => total + parseInt(criteria.input_ojt_point), 0);
        this.ojtScore.new = total + this.ojtScore.original;
        this.ojtCriterias[index].isMax = false;
      }
      this.updateTotalScore();
    },
    updateTotalScore() {
      this.totalScore =
        this.inclassScore.new + this.ojtScore.new + this.officerCreditScore;

      this.gradingLevel.forEach((item) => {
        // console.log(item);
        if (
          this.totalScore >= item.lowest_score &&
          this.totalScore <= item.highest_score
        ) {
          // console.log(item);
          this.selectedGrade = item;
          // console.log("reach", this.selectedGrade);
        }
      });
    },
    changeOfficerCredit() {
      this.isChecked = !this.isChecked;
      this.selectedOfficerCreditArray = [
        {
          id: null,
          name: "",
          score: 0,
        },
      ];

      this.officerCreditScore = 0;
      this.updateTotalScore();
    },
    removeAnswer(index) {
      this.officerCreditScore =
        this.officerCreditScore - this.selectedOfficerCreditArray[index].score;
      this.selectedOfficerCreditArray.splice(index, 1);
      this.updateTotalScore();
    },
    addNewOfficer() {
      if (
        this.officerCredits.length == this.selectedOfficerCreditArray.length
      ) {
        this.toast.error("Officer Credit reach in maximum amount.");
        return;
      } else {
        this.selectedOfficerCreditArray.push({
          id: null,
          name: "",
          score: 0,
        });
      }
    },
    getOfficerScore(val, index) {
      this.selectedOfficerCreditArray[index].score = val.score;
      this.selectedOfficerCreditArray[index].name = val.name;
      this.selectedOfficerCreditArray[index].id = val.id;

      this.officerCreditScore = this.selectedOfficerCreditArray.reduce(
        (accmulator, currentValue) => {
          return accmulator + currentValue.score;
        },
        0
      );
      this.updateTotalScore();
    },
    // getInclassInputPoint(value, index, point) {
    //   console.log(value, index, point)
    // },
    async getAllLevels() {
      this.loading = true;
      this.$Progress.start();
      await axios.get(`${this.baseUrl}admin/v1/levels`).then((response) => {
        this.levels = response.data.data;
        this.loading = false;
        this.$Progress.finish();
      });
    },

    async getAllFillmentData(
      program_id,
      batch_id,
      level,
      employee_id,
      // total_inclass_score, 
      // total_ojt_score
    ) {
      this.loading = true;
      this.$Progress.start();
      // let totalHighestScores = 0;

      await axios
        .get(
          `${this.baseUrl}admin/v2/grading-evaluation-get-fill-data/${program_id}/${batch_id}/${level}/${employee_id}`
        )
        .then((response) => {
          const data = response.data.data;
          if (data.inclass_score == 0) {
            this.isFullDetails = "User doesn't have Inclass Evaluation.";
            return;
          }
          if (data.ojt_score == 0) {
            this.isFullDetails = "User doesn't have OJT Evaluation.";
            return;
          }
          if (data.ojt_criterias.length == 0) {
            this.isFullDetails = "There is no ojt criterias.";
            return;
          }
          if (data.inclass_criterias.length == 0) {
            this.isFullDetails = "There is no inclass criterias.";
            return;
          }
          if (data.grading_level.length == 0) {
            this.isFullDetails = "There is no grading level";
          }
          this.allScores = {
            inclass_score: data.inclass_score,
            inclass_presentation_score: data.inclass_presentation_score,
            ojt_score: data.ojt_score,
            ojt_presentation_score: data.ojt_presentation_score,
            hrd_ojt_score: data.hrd_ojt_score,
            competency_score: data.competency_score,
          };

          this.isFullDetails = null;
          this.inclassCriterias = data.inclass_criterias;
          this.ojtCriterias = data.ojt_criterias;
          if(this.currentRouteName == "grading-evaluation-management-update") {
            this.officerCredits = data.officer_credit_criterias;
          }else {
            this.officerCredits = data.officer_credit_criterias.filter((item) => item.status == 1);
          }
          
          this.inclassScore = {
            original: data.inclass_score + data.inclass_presentation_score,
            new: data.inclass_score + data.inclass_presentation_score,
          };
          this.ojtScore = {
            original:
              data.ojt_score +
              data.ojt_presentation_score +
              data.competency_score + data.hrd_ojt_score,
            new:
              data.ojt_score +
              data.ojt_presentation_score +
              data.competency_score + data.hrd_ojt_score,
          };
          
this.inclassCriterias = this.inclassCriterias
  .map((item) => {
    if (this.currentRouteName == "grading-evaluation-management-update") {
      let matchingElement = this.inclass_criteria_id.find((element) => element.id === item.id);
      if (matchingElement) {
        return {
          id: item.id,
          name: item.name,
          point: item.point,
          input_inclass_point: matchingElement.input_inclass_point,
          isMax: false,
        };
      }
    } else {
      if (item.status === 1) {
        return {
          id: item.id,
          name: item.name,
          point: item.point,
          input_inclass_point: 0,
          isMax: false,
        };
      }
    }
  })
  .filter((item) => item !== undefined);
          // console.log(uInclassScore - this.inclassScore.new)

          this.ojtCriterias = this.ojtCriterias
            .map((item) => {
              if (
                this.currentRouteName == "grading-evaluation-management-update"
              ) {
                let matchingElement = this.ojt_criteria_id.find((element) => element.id === item.id);
                    if(matchingElement) {
                      return {
                    id: item.id,
                    name: item.name,
                    point: item.point,
                    input_ojt_point: matchingElement.input_ojt_point,
                    isMax: false,
                  };
                    }
              } else {
              if(item.status == 1) {
                return {
                  id: item.id,
                  name: item.name,
                  point: item.point,
                  input_ojt_point: 0,
                  isMax: false,
                };
              }
            }
            })
            .filter((item) => item !== undefined);
            console.log(this.inclassCriterias, 'incal')

           
          if (this.currentRouteName == "grading-evaluation-management-update") {
            const ojtTotal =  this.ojtCriterias.reduce((total, criteria) => total + parseInt(criteria.input_ojt_point), 0);
            this.ojtScore.new = ojtTotal + this.ojtScore.original;
            // this.inclassScore.new = total_inclass_score;
            // this.ojtScore.new = total_ojt_score;
            const inclassTotal = this.inclassCriterias.reduce((total, criteria) => total + parseInt(criteria.input_inclass_point), 0);
            this.inclassScore.new = inclassTotal + this.inclassScore.original;
          }

          const gradingResult = {
            g_a: data.grading_level.g_a,
            g_b: data.grading_level.g_b,
            g_c: data.grading_level.g_c,
            g_d: data.grading_level.g_d,
            g_e: data.grading_level.g_e,
          };

          const resultArray = Object.keys(gradingResult).map((key) => ({
            name: `${key.substring(2).toUpperCase()}`,
            lowest_score: gradingResult[key].lowest_score,
            highest_score: gradingResult[key].highest_score,
          }));
          this.gradingLevel = resultArray;

          // console.log(resultArray)
          this.updateTotalScore();
          // console.log(this.officerCredits)
        })
        .catch((error) => {
          console.log(error);
          this.error = error.response;
          this.toast.error("No evaluation for this employee.");
        });
      this.loading = false;
      this.$Progress.finish();
    },

    getSelectedProgram() {
      if (this.selectedProgram) {
        this.levels = [];
        this.selectedLevel = null;
        this.selectedBatch = null;
        this.clearData();
        this.gradingEvaluationDetail.program_id = this.selectedProgram.id;
        this.gradingEvaluationDetail.program_name = this.selectedProgram.name;
        this.gradingEvaluationDetail.program_short_name =
          this.selectedProgram.short_name;
        // this.getAllCriterias(this.gradingEvaluationDetail.program_id);
        this.getBatchByProgram(this.gradingEvaluationDetail.program_id);
      }
    },
    getSelectedBatch() {
      if (this.selectedBatch) {
        this.selectedLevel = null;
        this.levels = [];
        this.clearData();
        this.gradingEvaluationDetail.batch_id = this.selectedBatch.id;
        this.gradingEvaluationDetail.program_batch_no =
          this.selectedBatch.batch_no;
        this.gradingEvaluationDetail.available = this.selectedBatch.available;
        this.getAllLevels();
        // this.getEmployeeByBatch(this.gradingEvaluationDetail.batch_id);
      }
    },

    getSelectedLevel() {
      if (this.selectedLevel) {
        this.clearData();
        this.gradingEvaluationDetail.level = this.selectedLevel.name;
        this.getEmployeeByBatch(this.gradingEvaluationDetail.batch_id);
      }
    },

    getSelectedEmployee() {
      if (this.selectedEmployee) {
        this.gradingEvaluationDetail.employee_id =
          this.selectedEmployee.employee_id;
        this.gradingEvaluationDetail.employee_name =
          this.selectedEmployee.employee_name;
        this.gradingEvaluationDetail.join_date = this.selectedBatch.join_date;

        this.getAllFillmentData(
          this.gradingEvaluationDetail.program_id,
          this.gradingEvaluationDetail.batch_id,
          this.gradingEvaluationDetail.level,
          this.gradingEvaluationDetail.employee_id,
          0,
          0
        );
      }
    },
    clearData() {
      this.selectedEmployee = null;
      this.gradingEvaluationDetail.employee_id = "";
      this.gradingEvaluationDetail.employee_name = "";
      this.gradingEvaluationDetail.join_date = "";
      this.isChecked = false;
      this.isFullDetails = "";
      this.inclassCriterias = [];
      this.ojtCriterias = [];
      this.officerCredits = [];
      this.gradingLevel = [];
      this.inclass_criteria_id = [];
      this.ojt_criteria_id = [];
      this.inclassScore = { original: 0, new: 0 };
      this.ojtScore = { original: 0, new: 0 };
      this.officerCreditScore = 0;
      this.selectedGrade = null;
    },

    async getBatchByProgram(program_id) {
      this.loading = true;
      this.$Progress.start();
      await axios
        .get(`${this.baseUrl}admin/v1/program-batches/list/?program_id=${program_id}`)
        .then((response) => {
          this.batch = response.data.data;
          this.loading = false;
          this.$Progress.finish();
        })
        .catch(() => {
          this.toast.error("Not Found Batch!");
        });
    },
    async getEmployeeByBatch(batch_id) {
      this.loading = true;
      this.$Progress.start();
      await axios
      .get(
          `${this.baseUrl}admin/v1/program-batch/employees/list/${batch_id}`
        )
        .then((response) => {
          this.employeeLists = response.data.data.employee_ids.map(
            (item) => {
              return {
                employee_name: item.employee.name,
                employee_id: item.employee.emp_id,
              };
            }
          );

          this.loading = false;
          this.$Progress.finish();
        })
        .catch(() => {
          this.toast.error("Not Found Employee!");
        });
    },
    async getPrograms() {
      this.loading = true;
      this.$Progress.start();
      await axios
        .get(`${this.baseUrl}admin/v1/programs`)
        .then((response) => {
          this.programs = response.data.data;
          this.loading = false;
          this.$Progress.finish();
        })
        .catch(() => {
          this.toast.error("Not Found Program!");
        });
    },
    async createGradingEvaluation() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      
      let officerArray = this.selectedOfficerCreditArray.map(item => item.id);
      
      let inclassArray = this.inclassCriterias.map(item => {
        return {id: item.id, input_inclass_point: item.input_inclass_point};
      })
      let ojtArray = this.ojtCriterias.map(item => {
        return {id: item.id, input_ojt_point: item.input_ojt_point};
      })
      this.isLoading = true;

      const toSendData = {
        program_id: this.gradingEvaluationDetail.program_id,
        program_name: this.gradingEvaluationDetail.program_name,
        program_short_name: this.gradingEvaluationDetail.program_short_name,
        batch_id: this.gradingEvaluationDetail.batch_id,
        program_batch_no: this.gradingEvaluationDetail.program_batch_no,
        join_date: this.gradingEvaluationDetail.join_date,
        level: this.gradingEvaluationDetail.level,
        available: this.gradingEvaluationDetail.available,
        employee_id: this.gradingEvaluationDetail.employee_id,
        employee_name: this.gradingEvaluationDetail.employee_name,
        // grading_evaluation: {
          ojt_criteria_ids: ojtArray,
          inclass_criteria_ids: inclassArray,
          officer_credit_ids: officerArray.includes(null) ? null : officerArray,
          inclass_score: this.inclassScore.new,
          ojt_score: this.ojtScore.new,
          grade: this.selectedGrade.name,
        // },
      };

      await axios
        .post(`${this.baseUrl}admin/v2/grading-evaluation`, toSendData)
        .then(() => {
          this.$router.push({ name: "grading-evaluation-management" });
          this.toast.success("Grading Evaluation created successfully.");
        })
        .catch((error) => {
          this.error = error.response;
          this.toast.error("Something went wrong.");
        });
      this.isLoading = false;
    },

    async getGradindEvaluationById() {
      this.loading = true;
      await axios
        .get(
          `${this.baseUrl}admin/v2/grading-evaluation/${this.$route.params.id}`
        )
        .then(async (response) => {
          const data = response.data.data;
          if(data.increment_management_approve?.chlo_status == 1){
            this.isApproved = true;
          }
          this.selectedProgram = {
            id: data.program.id,
            name: data.program.title,
          };
          this.selectedBatch = {
            batch_id: data.batch.id,
            batch_no: data.batch.batch_no,
            join_date: data.batch.join_date,
          };
          this.selectedLevel = data.level;
          this.selectedEmployee = {
            employee_id: data.employee.id,
            employee_name: data.employee.name,
          };
          this.gradingEvaluationDetail = data;
          this.gradingEvaluationDetail.join_date = data.batch.join_date;
          this.inclass_criteria_id =
            data.inclass_criteria_ids;
          this.ojt_criteria_id = data.ojt_criteria_ids;

          this.program_owner_approved = data.program_owner_approved ? data.program_owner_approved : 0;
      
          if (data.officer_credits) {
            if (data.officer_credits.length > 0) {
              this.isChecked = true;
              this.selectedOfficerCreditArray = data.officer_credits;
              this.officerCreditScore = this.selectedOfficerCreditArray.reduce(
                (accmulator, currentValue) => {
                  return accmulator + currentValue.score;
                },
                0
              );
            }
          }

          await this.getAllFillmentData(
            data.program_id,
            data.batch_id,
            data.level,
            data.employee_id,
            data.inclass_score,
            data.ojt_score,
          );
          this.totalScore =
            this.inclassScore.new + this.ojtScore.new + this.officerCreditScore;
            this.selectedGrade = data.grade;
        })
        .catch((error) => {
          this.error = error.response;
          console.log("by id ", error);
          this.toast.error("Something went wrong");
        });
    },

    async updateGradingEvaluation() {
      let officerArray = [];

      // return 'hiiiii';
      this.selectedOfficerCreditArray.forEach((item) => {
        officerArray.push(item.id);
      });
      let inclassArray = this.inclassCriterias.map(item => {
        return {id: item.id, input_inclass_point: item.input_inclass_point};
      })
      let ojtArray = this.ojtCriterias.map(item => {
        return {id: item.id, input_ojt_point: item.input_ojt_point};
      })
      this.isLoading = true;

      const toSendData = {
        program_id: this.gradingEvaluationDetail.program_id,
        program_name: this.gradingEvaluationDetail.program_name,
        program_short_name: this.gradingEvaluationDetail.program_short_name,
        batch_id: this.gradingEvaluationDetail.batch_id,
        program_batch_no: this.gradingEvaluationDetail.program_batch_no,
        join_date: this.gradingEvaluationDetail.join_date,
        level: this.gradingEvaluationDetail.level,
        available: this.gradingEvaluationDetail.available,
        employee_id: this.gradingEvaluationDetail.employee_id,
        employee_name: this.gradingEvaluationDetail.employee_name,
        // grading_evaluation: {
          ojt_criteria_ids: ojtArray,
          inclass_criteria_ids: inclassArray,
          officer_credit_ids: officerArray.includes(null) ? null : officerArray,
          inclass_score: this.inclassScore.new,
          ojt_score: this.ojtScore.new,
          grade: this.selectedGrade.name,
        // },
      };

      await axios
        .put(
          `${this.baseUrl}admin/v2/grading-evaluation/${this.$route.params.id}`,
          toSendData
        )
        .then(() => {
          this.$router.push({ name: "grading-evaluation-management" });
          this.toast.success("Grading Evaluation updated successfully.");
        })
        .catch((error) => {
          this.error = error.response;
          this.toast.error("Something went wrong.");
        });
      this.isLoading = false;
    },

    async getExistedEmployees() {
      await axios
        .get(`${this.baseUrl}admin/v2/grading-evaluation-get-existed-employee`)
        .then((response) => {
          this.existedEmployee = response.data.data;
        })
        .catch((error) => {
          this.error = error.response;
          console.log("existed ", error);
          this.toast.error("Something went wrong.");
        });
    },
    isSelectableProgram(options) {
      if(!this.selectedProgram || !options) return true;
      return this.selectedProgram.id != options.id
    },
    isSelectableBatch(options) {
      if(!this.selectedBatch || !options) return true;
      return this.selectedBatch.id != options.id;
    },
    isSelectableLevel(options) {
      if(!this.selectedLevel || !options) return true;
      return this.selectedLevel.id!= options.id;
    },
    isSelectable(options) {
      // console.log(options)
      
      if (this.selectedEmployee) {
                const empExist = this.existedEmployee.some((item) => item.employee_id == this.selectedEmployee.employee_id && item.level == this.selectedLevel.name )
                // If the batch doesn't exist, add it to existedEmployee
                if (!empExist) {
                    this.existedEmployee = this.existedEmployee.filter(
                        (item) => !item.is_selected
                    );
                    this.existedEmployee.push({
                      employee_id: this.selectedEmployee.employee_id,
                      level: this.selectedLevel.name,
                      is_selected: true,
                    });
                }
            }
            // Now, check if the options.id exists in existedEmployee
            return !this.existedEmployee.find(
                (item) => item.employee_id == options.employee_id && item.level == this.selectedLevel.name 
            );
    //  return (
    //     this.existedEmployee.find((employee) => employee.employee_id === options.employee_id && employee.level == this.presentation.level && employee.type == this.presentation.type ) === undefined
    //   );
    },
    // isSelectable(options) {
    //   // console.log(options)
    //   return (
    //     this.existedEmployee.find(
    //       (employee) =>
    //         employee.employee_id === options.employee_id &&
    //         employee.level == this.gradingEvaluationDetail.level &&
    //         employee.batch_id ==
    //           this.gradingEvaluationDetail.batch_id &&
    //         employee.program_id == this.gradingEvaluationDetail.program_id
    //     ) === undefined
    //   );
    // },
    isSelectableOfficer(options) {
      if(!this.selectedOfficerCreditArray || !options) return true;
      return !this.selectedOfficerCreditArray.find((officer) => officer.id == options.id);
      // return this.criteria.level != options.name;
    }
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },

  async created() {
    if (this.currentRouteName == "grading-evaluation-management-update") {
      await this.getGradindEvaluationById();
    }
    await this.getPrograms();
    this.getExistedEmployees();

    // await this.getExistedEmployees();
  },
};
</script>

<style>
.custom-selectCSS {
  --vs-dropdown-min-width: 160px;
  --vs-dropdown-max-height: 200px;
}
.custom-v-select .vs__dropdown-toggle .vs__open-indicator::before {
  display: none;
}
.checkBox {
  margin-left: 10px;
  margin-top: 3px;
}
</style>
